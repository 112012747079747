.header-menu-link {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #68686c;
  position: relative;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    width: 50%;
  }
}
.header-menu-link-active {
  color: #2d7ff9;
  position: relative;
}

.header-menu-link-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 3px;
  background: #2d7ff9;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  @media only screen and (max-width: 992px) {
    top: 0;
    border-radius: 0px 0px 4px 4px;
  }
}
